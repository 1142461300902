<template>
  <div class="home" @scroll="handleScroll">
    <!-- bg -->
    <div class="home-bg"></div>
    <!-- 主要内容 -->
    <div class="home-main">
      <div class="flex flex-between align-center home-main-search">
        <div class="flex flex-left align-center home-main-search-l" @click="gotoPagesFunc('search')">
          <img class="home-main-search-l-icon" src="../../assets/img/home/search.png" alt="">
          <input class="home-main-search-l-input" type="text" placeholder="请输入要搜索的内容" readonly>
        </div>
        <div class="flex flex-right align-center home-main-search-r" @click="gotoPagesFunc('xs')">
          <img class="home-main-search-r-icon" src="../../assets/img/home/sx.png" alt="">
          筛选
        </div>
      </div>
      <!-- banner -->
      <div class="home-main-banner">
        <van-swipe :autoplay="3000" class="home-main-banner-swiper">
          <van-swipe-item v-for="image in bannerList" :key="image" @click="goLinkFunc(image.clickUrl)">
            <img :src="image.photoUrl" class="home-main-banner-swiper-img"/>
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 公告 -->
      <div class="home-main-notice" v-if="noticeList.length > 0">
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
        >
          <van-swipe-item class="flex flex-left align-center " v-for="(item,index) in noticeList" :key="item.id" @click="openNoticeFunc(index)">
            <img class="home-main-notice-icon" src="../../assets/img/home/notice.png" alt="">
            <span class="home-main-notice-msg">{{item.title}}</span>
          </van-swipe-item>
        </van-swipe>
      </div>
      <!-- 分类 -->
      <ul class="flex flex-between home-main-categary" style="display:none">
        <li class="flex flex-center flex-wrap home-main-categary-list" v-for="item in categaryList" :key="item.id" @click="gotoPagesFunc('category', item.category_id)">
          <div class="home-main-categary-list-icon">
            <img :src="item.category_icon" alt="">
          </div>
          <div class="home-main-categary-list-name">{{item.category_name}}</div>
        </li>
      </ul>

      <!-- 20220119 新版首页分类 -->
      <ul class="flex flex-left flex-wrap home-main-newcategary">
        <li class="flex flex-center flex-wrap home-main-newcategary-list" v-for="item in categaryList" :key="item.id" @click="gotoPagesFunc('category', item.category_id)">
          <div class="home-main-newcategary-list-icon">
            <img :src="item.category_icon" alt="">
          </div>
          <div class="home-main-newcategary-list-name">{{item.category_name}}</div>
        </li>
      </ul>
      <!-- 20220119 种草达人专区特权 -->
      <div class="home-main-daperson" style="display:none;" @click="goDaPersonFunc">
        <img src="../../assets/img/home/home-daren.png" alt="">
      </div>

      <!-- 20220119 超值年货 如虎添翼 -->
      <div class="home-main-adver">
        <img src="../../assets/img/home/home-adver.png" alt="">
        <div class="home-main-adver-img home-main-adver-img1">
          <img src="../../assets/img/home/home-adv1.png" alt="" @click="selectTagFunc1(3)">
        </div>
        <div class="home-main-adver-img home-main-adver-img2">
          <img src="../../assets/img/home/home-adv2.png" alt="" @click="selectTagFunc1(1)">
        </div>
        <div class="home-main-adver-img home-main-adver-img3">
          <img src="../../assets/img/home/home-adv3.png" alt="" @click="selectTagFunc1(2)">
        </div>
      </div>

      <!-- 20220119 商品列表tag -->
      <van-tabs v-model:active="tagIdx" animated background="#F6F6F6" @click-tab="onClickTab">
        <van-tab v-for="(item, index) in tagList" v-bind:key="index">
          <template #title>
            <div class="flex flex-center align-center home-main-tag-main">
              <div class="home-main-tag-icon" v-if="item.icon"><img :src="item.icon" alt=""></div>{{item.name}}
            </div>
            <div class="home-main-tag-main-bot" v-if="tagIdx == index" :class="item.icon?'':'home-main-tag-main-bot1'"><img src="../../assets/img/home/home-active.png" alt=""></div>
          </template>
          <!-- 商品列表 -->
          <div class="flex flex-between waterf">
            <!-- 瀑布流左边 -->
            <div class="waterf-col">
              <div :class="'waterf-col-cont'+index">
                <div class="waterf-col-item waterf-col-item1" v-if="tagIdx == 0" @click="goSecondsKillFunc">
                  <img src="../../assets/img/secondskill/secondsKill.png" alt="">
                </div>
                <div class="waterf-col-item" v-for="item2 in item.goodsL" :key="item2.goodId" @click="gotoPagesFunc('goodsdetail', item2.goodId)">
                  <div class="waterf-col-item-img">
                    <img :src="item2.goodPic" alt="">
                  </div>
                  <div class="waterf-col-item-info">
                    <div class="waterf-col-item-info-name">{{item2.goodName}}</div>
                    <div class="flex flex-left align-center waterf-col-item-info-brand">
                      <img :src="item2.brandIcon" alt="">{{item2.brandName}}
                    </div>
                    <div class="flex flex-left align-center waterf-col-item-info-yh" v-if="item2.coupon || item2.score">
                      <span class="waterf-col-item-info-yh-span" v-if="item2.coupon">{{item2.coupon.name}}</span>
                      <span class="waterf-col-item-info-yh-span" v-if="item2.score">得{{item2.score}}龙珠</span>
                    </div>
                    <div class="waterf-col-item-info-price">
                      <div class="waterf-col-item-info-price-l">
                        ¥<span class="waterf-col-item-info-price-l-num">{{item2.price}}</span>起
                        <span>灵龙价</span>
                      </div>
                      <div class="waterf-col-item-info-price-sale" style="text-decoration: line-through;color:#999;">市场价¥{{parseFloat(item2.goodsOriginalPrice/100).toFixed(2)}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 瀑布流右边 -->
            <div class="waterf-col">
              <div :class="'waterf-col-cont'+index">
                <div class="waterf-col-item" v-for="item1 in item.goodsR" :key="item1.goodId" @click="gotoPagesFunc('goodsdetail', item1.goodId)">
                  <div class="waterf-col-item-img">
                    <img :src="item1.goodPic" alt="">
                  </div>
                  <div class="waterf-col-item-info">
                    <div class="waterf-col-item-info-name">{{item1.goodName}}</div>
                    <div class="flex flex-left align-center waterf-col-item-info-brand">
                      <img :src="item1.brandIcon" alt="">{{item1.brandName}}
                    </div>
                    <div class="flex flex-left align-center waterf-col-item-info-yh" v-if="item1.coupon || item1.score">
                      <span class="waterf-col-item-info-yh-span" v-if="item1.coupon">{{item1.coupon.name}}</span>
                      <span class="waterf-col-item-info-yh-span" v-if="item1.score">得{{item1.score}}龙珠</span>
                    </div>
                    <div class="waterf-col-item-info-price">
                      <div class="waterf-col-item-info-price-l">
                        ¥<span class="waterf-col-item-info-price-l-num">{{item1.price}}</span>起
                        <span>灵龙价</span>
                      </div>
                      <div class="waterf-col-item-info-price-sale" style="text-decoration: line-through;color:#999;">市场价¥{{parseFloat(item1.goodsOriginalPrice/100).toFixed(2)}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 已经到底了 -->
          <toast-bottom v-if="item.allGoodsList.length > 0&& item.finished"></toast-bottom>
          <!-- 暂无数据 -->
          <van-empty v-show="item.allGoodsList.length == 0" description="暂无数据" />
        </van-tab>
      </van-tabs>
    </div>
    <!-- 弹窗 -->
    <receive-coupon v-if="isShowCoupon"></receive-coupon>
    <!-- 弹窗成为种草达人 -->
    <div class="home-popup" v-if="isShowZc">
        <div class="home-popup-main home-popup-main1">
          <div class="home-popup-main-top">成为种草达人</div>
          <div class="flex flex-left align-center home-popup-main-coupon home-popup-main1-coupon">
            <div class="flex flex-center align-center home-popup-main1-coupon-price"></div>
            <div class="home-popup-main1-coupon-info">
              <div class="home-popup-main1-coupon-info-name">成为“灵龙好物”种草达人</div>
              <div class="home-popup-main1-coupon-info-jine">获得推广奖励佣金</div>
            </div>
          </div>
          <div class="home-popup-main1-info">
            <div class="home-popup-main1-info-list">达人权益介绍：</div>
            <div class="flex flex-left align-center home-popup-main1-info-list"><i></i>专享商品优惠价</div>
            <div class="flex flex-left align-center home-popup-main1-info-list"><i></i>成功推广灵龙惠生活商品得佣金奖励</div>
            <div class="flex flex-left align-center home-popup-main1-info-list"><i></i>推荐身边好友成为达人，可获得推荐奖励</div>
            <div class="flex flex-left align-center home-popup-main1-info-list"><i></i>免费定期参加基础新媒体的培训</div>
          </div>
          <div class="flex flex-between home-popup-main1-btn">
            <van-button round type="default" class="home-popup-main1-btn-list">取消</van-button>
            <van-button round type="primary" color="#E62129" class="home-popup-main1-btn-list">前往达人专区</van-button>
          </div>
          <div class="home-popup-main-close"></div>
        </div>
    </div>
    <!-- 公告内容 -->
    <div class="home-popup" v-if="isShowGg">
      <div class="flex flex-column home-popup-main2">
        <div class="flex flex-center align-center toast-title">
          <span></span>公告内容<span></span>
        </div>
        <div class="toast-msg" v-html="noticeDetail"></div>
        <van-button type="default" size="large" class="toast-btn" @click="isShowGg = false;">
          已阅读
        </van-button>
        <div class="home-popup-main2-close" @click="isShowGg = false;"></div>
      </div>
    </div>

    <!-- 20220119 欢迎来到灵龙惠生活 -->
    <div class="home-popup" style="display:none;" v-if="showKnown">
      <div class="home-known">
        <div class="flex flex-center align-center home-known-header" :style="{background: 'url('+require('../../assets/img/home/home-intru.png')+')no-repeat center center', backgroundSize: 'cover'}">欢迎来到灵龙惠生活</div>
        <div class="home-known-main">
          <div class="home-known-one">
            <div class="home-known-one-con">
              理性种草·寻好货<br />
              在这里你可以看到找到<br />
              性价比高、质量好的品牌商品
            </div>
          </div>
          <div class="home-known-two">
            <div class="home-known-two-title">你可以成为灵龙会员</div>
            <div class="home-known-two-con">
              会员拥有以下权益：<br />
              1. 享受会员价，商品均低于市场官方价。<br />
              2. 会员购买商品成功得龙珠，龙珠在下次购物可作为购物优惠抵扣实际支付金额！部分商品最高可抵扣50元！
            </div>
          </div>
          <div class="home-known-two">
            <div class="home-known-two-title">你可以成为“种草达人”</div>
            <div class="home-known-two-con">
              1. 部分商品享受灵龙种草达人价。<br />
              2. 种草达人购买获得龙珠和可使用的龙珠优惠抵扣上限均比普通会员高出约50%。<br />
              3. 种草身边人任何灵龙商品，被拔草，则可获取推广长期奖励。<br />
              4. 达人身边人注册会员同时成为达人，则可以获取发现种草官的一次性奖励。<br />
              5. 成为种草达人，有机会被选中免费学习价值3999元的自媒体相关课程。
            </div>
          </div>
          <div class="home-known-two">
            <div class="home-known-two-title">你可以成为“门店达人”</div>
            <div class="home-known-two-con">
              1. 享受所有“种草达人”的权益。<br />
              2. 门店达人会有门店进货专享商品和专享价为你的门店生意低成本寻好货，为生意如虎添翼。
            </div>
          </div>
          <div style="height: 60px;"></div>
        </div>
        <div class="flex flex-center align-center home-known-btn">
          <van-button plain type="primary" size="large" round color="#E62129" class="home-known-btn-con" @click="isFirst = 0;showKnown = false;">回到首页</van-button>
        </div>
      </div>
    </div>

    <!-- 20220119 领积分红包 -->
    <div class="home-redpack" @click="groupFun">
      <img src="../../assets/img/home/redpack.png" alt="">
    </div>
    <div class="home-reload" @click="reloadFunc">
      <img src="../../assets/img/home/reload.png" alt="">
    </div>
    <div class="home-backtop" v-if="isPageFour" @click="backtopFunc">
      <img src="../../assets/img/home/backtop.png" alt="">
    </div>

    <!-- 20220119 引导页 -->
    <div class="home-guide" style="display:none;" v-if="isFirst == 0">
      <div class="home-guide-main">
        <div class="flex flex-center align-center home-guide-main-header" :style="{background: 'url('+require('../../assets/img/home/home-intru.png')+')no-repeat center center', backgroundSize: 'cover'}">这里是灵龙惠生活首页</div>
        <div class="home-guide-main-cont">
          在这里所有用户均可浏览商品、<br />
          寻找普通商品查看参加活动、<br />
          查看商品详情、购买商品、分享商品。
        </div>
        <div class="flex flex-center align-center home-guide-main-btn" @click="isFirst = 1;">下一步</div>
      </div>
      <div class="home-guide-line" :style="{background: 'url('+require('../../assets/img/home/guide-line.png')+')no-repeat center center', backgroundSize: 'cover'}"></div>
      <div class="home-guide-home" :style="{background: 'url('+require('../../assets/img/home/guide-home.png')+')no-repeat center center', backgroundSize: 'cover'}"></div>
    </div>
    <div class="home-guide" style="display:none;" v-if="isFirst == 1">
      <div class="home-guide-main">
        <div class="flex flex-center align-center home-guide-main-header" :style="{background: 'url('+require('../../assets/img/home/home-intru.png')+')no-repeat center center', backgroundSize: 'cover'}">这里是达人专区</div>
        <div class="home-guide-main-cont home-guide-main-cont1">
          1. 如你仅是会员身份，则可在此专区看到成为种草达人需要的完成任务的相关商品列表。<br />
          2. 如你已经是种草达人，则此专区可以查看自己的权益，并且会显示“门店达人”的资质填写入口（如没有门店，则无视）。<br />
          3.  如你已经是门店达人，则可以在此区域看到门店达人专属商品浏览下单区域。
        </div>
        <div class="flex flex-center align-center home-guide-main-btn" @click="knownFunc">已了解 回到首页</div>
      </div>
      <div class="home-guide-line home-guide-line1" :style="{background: 'url('+require('../../assets/img/home/guide-line.png')+')no-repeat center center', backgroundSize: 'cover'}"></div>
      <div class="home-guide-home home-guide-home1" :style="{background: 'url('+require('../../assets/img/home/guide-daren.png')+')no-repeat center center', backgroundSize: 'cover'}"></div>
    </div>
    <!-- 客服2 -->
    <service-popup></service-popup>
  </div>
</template>
<script src="./index.js"></script>
<style >
.toast-btn .van-button__text{
  font-size: 15px !important;
  color: #E62129 !important;
}
.toast-btn{
  border-width: 0px !important;
  border-radius: 0 0 10px 10px;
}
:root{
  --van-tabs-bottom-bar-width: 0px;
}
</style>
<style lang="scss" scoped>
@import "./index.scss";
.waterf{
  width: 100%;
  height: auto;
  padding-top: 20px;
  &-col{
    width: 168px;
    height: auto;
    &-item{
      width: 100%;
      height: auto;
      background-color: #fff;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 10px;
      &-img{
        width: 100%;
        height: auto;
        img{
          width: 100%;
          height: auto;
          vertical-align: top;
        }
      }
      &-info{
        width: auto;
        height: auto;
        padding: 10px;
        &-name {
            display: -webkit-box;
            width: auto;
            height: auto;
            color: #333333;
            font-size: 14px;
            font-weight: 400;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        &-brand {
            width: auto;
            height: auto;
            color: #999;
            font-size: 10px;
            font-weight: 400;
            text-align: left;
            overflow: hidden;
            margin-top: 4px;
            text-overflow: ellipsis;
            white-space: nowrap;
            img{
              width: 16px;
              height: 16px;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 5px;
            }
        }
        &-yh {
            width: auto;
            height: auto;
            margin-top: 8px;
            &-span {
                width: auto;
                height: auto;
                padding: 0 3px;
                color: #E17125;
                font-size: 10px;
                font-weight: 400;
                margin-right: 5px;
                text-align: left;
                background-color: #FBEEE2;
            }
            &-span:last-child {
                margin-right: 0;
            }
        }
        &-price {
            width: auto;
            height: auto;
            margin-top: 10px;
            &-l {
                width: auto;
                height: auto;
                color: #E62129;
                font-size: 10px;
                font-weight: bold;
                &-num {
                    font-size: 15px;
                    margin: 0 2px;
                }
                &-toast {
                    margin-left: 5px;
                }
            }
            &-sale {
                width: auto;
                height: auto;
                color: #666666;
                font-size: 10px;
                font-weight: 400;
            }
        }
        &-sale {
            width: auto;
            height: auto;
            padding: 0px 10px;
            margin-top: 5px;
            &-l {
                width: auto;
                height: auto;
                color: #E33A32;
                font-size: 10px;
                font-weight: 400;
                padding: 0 5px;
                background-color: rgba(227, 58, 50, 0.08);
            }
        }
      }
    }
    &-item1{
      height: 122px;
      img{
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }
  }
}
</style>