<template>
  <div>
    <van-popup
      v-model:show="showGroup"
      position="center"
      :style="{ width: '100%' }"
      :close-on-click-overlay="false"
      class="add-serve-popup"
    >
      <div class="close-img" @click="showGroup = false">
        <img src="@/assets/img/loginRegister/close.png" />
      </div>
      <div class="bottom" :style="backgroundDiv">
        <div class="welcome">
          <p class="flex flex-center align-center">加灵龙企业客服微信</p>
          <p class="flex flex-center align-center">
            领取更多活动优惠，一起讨论如何更省钱
          </p>
        </div>
        <div class="qr-code">
          <img src="@/assets/img/user/home/enterprise.jpg" />
        </div>
        <p class="qr-bottom">截图此二维码，用微信扫码关注企业客服</p>
        <p class="copy" @click="copyFun">复制企业微信号</p>
      </div>
    </van-popup>
  </div>
</template>
<script>
import useClipboard from "vue-clipboard3"
import { inject } from 'vue'
export default {
  setup() {
    let showGroup = inject('showgroup');
    return {
      showGroup
    }
  },
  data() {
    return {
      backgroundDiv: {
          backgroundImage: "url(" + require("@/assets/img/user/home/back.png") + ")",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
      },
    }
  },
  created() {

  },
  methods: {
    async copyFun() {
        try {
            const { toClipboard } = useClipboard()
            await toClipboard('18017657468')
            this.$toast.success("已复制企业微信号")
        } catch (e) {
            console.error(e)
        }
    },
  }
}
</script>
<style lang="scss">
.add-serve-popup {
    width: 280px !important;
    top: 60px !important;
    transform: translateX(-50%) !important;
    background: none !important;
    .close-img {
      text-align: right;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .top {
      width: 100%;
      height: 98px;
      background: #ffffff;
      -webkit-border-radius: 8px;
      -moz-border-radius: 8px;
      -ms-border-radius: 8px;
      -o-border-radius: 8px;
      border-radius: 8px;
      margin-top: 10px;
      position: relative;
      &-title {
        width: 100%;
        height: auto;
        padding: 15px 0;
        color: #333;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        span {
          width: 10px;
          height: 1px;
          background-color: #e62129;
        }
        span:nth-child(1) {
          margin-right: 10px;
        }
        span:nth-child(2) {
          margin-left: 10px;
        }
      }
      .fast {
        height: 16px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        text-align: center;
      }
      .left,
      .right {
        position: absolute;
        width: 14px;
        height: 48px;
      }
      .left {
        top: 79px;
        left: 20px;
      }
      .right {
        top: 79px;
        right: 20px;
      }
    }
    .bottom {
      width: 100%;
      height: 415px;
      margin-top: 10px;
      padding-top: 20px;
      .welcome {
        height: 36px;
        margin: 0 0 50px;
        p {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          text-align: center;
        }
      }
      .qr-code {
        width: 220px;
        height: 220px;
        margin: 0 auto;
        text-align: center;
        img{
           width: 100%;
           height: 100%;
        }
      }
      .qr-bottom {
        height: 14px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 14px;
        margin-top: 10px;
        text-align: center;
      }
      .copy{
         width: 230px;
         height: 40px;
         line-height: 40px;
         text-align: center;
         background: #E62129;
         -webkit-border-radius: 22px;
         border-radius: 22px;
         margin: 15px auto 0;
         font-size: 15px;
         font-family: PingFangSC-Medium, PingFang SC;
         font-weight: 500;
         color: #FFFFFF;
         text-align: center;
      }
    }
  }
</style>