<template>
  <div class="home-popup" v-if="isShow">
        <div class="home-popup-main">
          <div class="home-popup-main-top">领取优惠券</div>
          <div class="flex flex-left align-center home-popup-main-coupon">
            <div class="flex flex-center align-center home-popup-main-coupon-price">
              <div class="home-popup-main-coupon-price-money">¥<span>{{parseFloat(couponInfo.value/100).toFixed()}}</span></div>
            </div>
            <div class="home-popup-main-coupon-info">
              <div class="home-popup-main-coupon-info-name">{{couponInfo.name}}</div>
              <!-- <div class="home-popup-main-coupon-info-jine">立减5元</div> -->
            </div>
          </div>
          <div class="home-popup-main-msg">
            <p>使用范围：{{couponInfo.content}}</p>
            <p>截止使用时间：{{couponInfo.validEndTime}}</p>
            <p>最终解释权归平台所有</p>
          </div>
          <div class="home-popup-main-btn">
            <van-button type="primary" color="#E62129" round size="large" style="height: 100%;" @click="receiveCouponFunc">立即领取</van-button>
          </div>
          <div class="home-popup-main-close" @click="isShow = false;"></div>
        </div>
    </div>
</template>
<script>
import {
    getNewMemberCoupon
} from '@/utils/home.js';
import {
    receiveCoupon
} from '@/utils/goods.js';
export default {
  data() {
    return {
      couponInfo: '',
      isShow: false,
    }
  },
  created() {
    // this.getNewMemberCouponFunc();
  },
  methods: {
    // 是否弹出优惠券弹窗
    getNewMemberCouponFunc() {
        getNewMemberCoupon({ memberId: this.$cookies.get('memberId') }).then(res => {
            if (res.code == 0) {
                this.isShow = true;
                this.couponInfo = res.data;
            }
        })
    },
    // 领取优惠券
    receiveCouponFunc() {
        let data = {
            memberId: this.$cookies.get('memberId'),
            couponId: this.couponInfo.id
        }
        receiveCoupon(data).then(res => {
            console.log('领取优惠券', res)
            // if (!this.$cookies.get('isLogin')) {
            //     this.$router.push({
            //     path: '/login'
            //     })
            // }
            if (res.code == 0) {
                this.$toast('领取成功！')
                this.isShow = false;
            } else {
                this.$toast(res.message)
            }
        })
    },
  }
}
</script>
<style lang="scss" scoped>
.home-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2001;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.7);
    &-main {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 280px;
        height: 345px;
        background: url("../assets/img/home/popup-bg.png")no-repeat center top;
        background-size: cover;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        &-top {
            position: relative;
            left: 50%;
            top: -32px;
            transform: translateX(-50%);
            width: 238px;
            height: 64px;
            color: #fff;
            font-size: 15px;
            font-weight: 500;
            line-height: 56px;
            text-align: center;
            background: url("../assets/img/home/coupon.png")no-repeat center top;
            background-size: cover;
            -webkit-transform: translateX(-50%);
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
        }
        &-coupon {
            position: relative;
            top: -20px;
            width: auto;
            height: auto;
            padding: 0 25px;
            &-price {
                width: 84px;
                height: 68px;
                background: url("../assets/img/home/coupon-bg.png")no-repeat center center;
                background-size: cover;
                &-money {
                    width: auto;
                    height: auto;
                    color: #E02E24;
                    font-size: 24px;
                    font-weight: 500;
                    span {
                        font-size: 44px;
                        margin-left: 4px;
                    }
                }
            }
            &-info {
                width: auto;
                height: auto;
                flex: 1;
                margin-left: 20px;
                overflow: hidden;
                &-name,
                &-jine {
                    width: 100%;
                    height: auto;
                    font-size: 14px;
                    font-weight: 500;
                    text-align: left;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
                &-name {
                    color: #333333;
                }
                &-jine {
                    color: #E62129;
                    margin-top: 2px;
                }
            }
        }
        &-msg {
            position: relative;
            top: 32px;
            width: auto;
            height: auto;
            padding: 0 25px;
            p {
                width: auto;
                height: auto;
                color: #666666;
                font-size: 12px;
                font-weight: 400;
                text-align: left;
                line-height: 1.5;
                margin-bottom: 5px;
            }
        }
        &-btn {
            position: relative;
            top: 55px;
            width: auto;
            height: 44px;
            padding: 0 25px;
        }
        &-close {
            position: absolute;
            top: -60px;
            right: 0;
            width: 24px;
            height: 24px;
            background: url("../assets/img/close.png")no-repeat center center;
            background-size: cover;
        }
    }
}
</style>