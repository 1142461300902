import toastBottom from '@/components/bottom.vue'
import {
    getBanner,
    getNotice,
    getCategory,
    getHomeGoods
} from '@/utils/home.js';
import receiveCoupon from '@/components/receiveCoupon.vue';
// import waterfall from '@/components/waterfall.vue'
import servicePopup from '@/components/service.vue';
import { provide, ref } from 'vue';
export default {
    // waterfall
    setup() {
        let showGroup = ref(false);
        provide('showgroup', showGroup);
        return {
            showGroup
        }
    },
    components: { toastBottom, receiveCoupon, servicePopup },
    data() {
        return {
            bannerList: [],
            categaryList: [],
            noticeList: [],
            tagList: [{
                name: '推荐',
                icon: require('../../assets/img/home/home-bp.png'),
                page: 1,
                oldPage: 0,
                finished: false,
                allGoodsList: [],
                goodsL: [],
                goodsR: [],
            }, {
                name: '迎接夏天',
                icon: require('../../assets/img/home/home-nh.png'),
                page: 1,
                oldPage: 0,
                finished: false,
                allGoodsList: [],
                goodsL: [],
                goodsR: [],
            }, {
                name: '清洁达人',
                icon: '',
                page: 1,
                oldPage: 0,
                finished: false,
                allGoodsList: [],
                goodsL: [],
                goodsR: [],
            }, {
                name: '温馨生活',
                icon: '',
                page: 1,
                oldPage: 0,
                finished: false,
                allGoodsList: [],
                goodsL: [],
                goodsR: [],
            }, {
                name: '美味推荐',
                icon: '',
                page: 1,
                oldPage: 0,
                finished: false,
                allGoodsList: [],
                goodsL: [],
                goodsR: [],
            }, {
                name: '出行必备',
                icon: '',
                page: 1,
                oldPage: 0,
                finished: false,
                allGoodsList: [],
                goodsL: [],
                goodsR: [],
            }], //限时积分专区
            // }, {
            //     name: '多喝水！',
            //     icon: '',
            //     page: 1,
            //     oldPage: 0,
            //     finished: false,
            //     allGoodsList: [],
            //     goodsL: [],
            //     goodsR: [],
            // {
            //     name: '情人节',
            //     icon: require('../../assets/img/home/home-qr.png')
            // },
            tagIdx: 0,
            isShowZc: false,
            isShowGg: false,
            goodsList: [],
            loading: false,
            couponInfo: '',
            noticeDetail: '',
            isDaPerson: '',
            showKnown: false,
            imgsArr: [],
            aIndex: 0,
            isPageFour: false,
            isFirst: -1, // 引导
            isShowCoupon: false,
        }
    },
    watch: {
        $route(to, from) {
            if (from.path == '/categary' && this.$route.query.tagIdx) {
                this.tagIdx = 1;
                this.page = 1;
                this.oldPage = 0;
                this.goodsList = [];
                this.loading = false;
                this.finished = false;
                this.getHomeGoodsFunc();
            }
        }
    },
    created() {
        this.getBannerFunc();
        this.getNoticeFunc();
        this.getCategoryFunc();
        this.getHomeGoodsFunc();
        this.isDaPerson = this.$cookies.get('isDaPerson');
        // if (this.$cookies.get('isFirst') && this.$cookies.get('isFirst') == 0) {
        //     this.showKnown = true;
        // }
    },
    mounted() {

    },
    methods: {
        handleScroll() { //获取滚动时的高度
            let scrollTop = document.getElementsByClassName('home')[0].scrollTop;
            let clientHeight = document.getElementsByClassName('home-main')[0].clientHeight;
            let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            if (clientHeight - (scrollTop + windowHeight) < 200) {
                console.log('范德萨发生')
                this.goodsList = [];
                this.getHomeGoodsFunc();
            }

            // 判断是否滚动到第四屏
            if (scrollTop / windowHeight >= 4) {
                this.isPageFour = true;
            } else {
                this.isPageFour = false;
            }
        },
        itemHeight(index) {
            // 计算左右两边的高度
            this.$nextTick(() => {
                let heightArr = document.getElementsByClassName('waterf-col-cont' + this.tagIdx);
                let lHeight = heightArr[0].offsetHeight;
                let rHeight = heightArr[1].offsetHeight;
                if (index < this.goodsList.length) {
                    if (lHeight <= rHeight) {
                        this.tagList[this.tagIdx].goodsL.push(this.goodsList[index])
                    } else {
                        this.tagList[this.tagIdx].goodsR.push(this.goodsList[index])
                    }
                    this.aIndex += 1;
                    this.itemHeight(this.aIndex);
                }
            })
        },
        // 回到第一屏
        backtopFunc() {
            document.getElementsByClassName('home')[0].scrollTop = 0
        },
        // 刷新页面
        reloadFunc() {
            window.location.reload()
        },
        // 获取banner
        getBannerFunc() {
            let data = {
                memberId: this.$cookies.get('memberId'),
                advertType: 1
            };
            getBanner(data).then(res => {
                console.log('banner', res)
                this.bannerList = res.data.advertList
            })
        },
        // 获取公告
        getNoticeFunc() {
            let data = {
                pageNo: 1,
                pageSize: 3,
                type: 1
            }
            getNotice(data).then(res => {
                console.log('notice', res.data)
                this.noticeList = res.data
            })
        },
        // 获取分类
        getCategoryFunc() {
            let data = {
                goodBigtype: 2,
                level: 2
            }
            getCategory(data).then(res => {
                console.log('分类', res.data)
                let arr = JSON.parse(JSON.stringify(res.data.category_list));
                // if (arr.length > 4) {
                //     arr.splice(4);
                // }
                // console.log('arr', arr)
                // arr.push({
                //     category_id: '',
                //     category_name: '更多',
                //     category_icon: require('../../assets/img/home/categary5.png')
                // })
                this.categaryList = arr;
            })
        },
        // 获取商品列表
        getHomeGoodsFunc() {
            if (this.tagList[this.tagIdx].oldPage == this.tagList[this.tagIdx].page) return false;
            this.tagList[this.tagIdx].oldPage = this.tagList[this.tagIdx].page;
            let channelVal = '',
                tagName = '';
            switch (this.tagIdx) {
                case 0:
                    channelVal = 0;
                    break;
                    // case 1:
                    //     channelVal = 1;
                    //     break;
                default:
                    channelVal = '';
                    tagName = this.tagList[this.tagIdx].name;
                    break;
            }
            let data = {
                memberId: this.$cookies.get('memberId'),
                goodName: '',
                sortMethod: 1,
                pageNo: this.tagList[this.tagIdx].page,
                pageSize: 10,
                channel: channelVal, //(0=普通专区,1=限时积分专区,2=达人专区,4=情人节)
                categoryFirstId: '',
                tag: tagName,
            }
            getHomeGoods(data).then(res => {
                console.log('getHomeGoods', res)
                if (res) {
                    let arr = res.result ? res.result.goodList : [];
                    arr.forEach(item => {
                        // this.allGoodsList.push(item)
                        this.tagList[this.tagIdx].allGoodsList.push(item)
                    })

                    this.goodsList = arr;
                    console.log('allGoodsList', this.tagList[this.tagIdx].allGoodsList.length)

                    // 加载状态结束
                    // this.loading = false;
                    this.aIndex = 0;
                    this.itemHeight(this.aIndex);

                    // 数据全部加载完成
                    if (arr.length < 10) {
                        this.tagList[this.tagIdx].finished = true;
                    } else {
                        this.tagList[this.tagIdx].page++;
                    }
                } else {
                    this.tagList[this.tagIdx].finished = true;
                }
            })
        },
        // 新 选择tag
        onClickTab() {
            this.goodsList = [];

            // this.tagList[this.tagIdx].page = 1;
            // this.tagList[this.tagIdx].oldPage = 0;
            // this.tagList[this.tagIdx].goodsL = [];
            // this.tagList[this.tagIdx].goodsR = [];
            // this.tagList[this.tagIdx].allGoodsList = [];
            // this.tagList[this.tagIdx].finished = false;

            if (this.tagList[this.tagIdx].page == 1) {
                this.getHomeGoodsFunc();
            }
        },
        selectTagFunc1(idx) {
            this.tagIdx = idx;
            // this.goodsList = [];
            // this.page = 1;
            // this.oldPage = 0;
            // this.goodsL = [];
            // this.goodsR = [];
            // this.allGoodsList = [];
            // this.finished = false;
            if (this.tagList[this.tagIdx].page == 1) {
                this.getHomeGoodsFunc();
            }
        },
        // 选择的tag
        selectTagFunc(idx) {
            this.tagIdx = idx;
            this.goodsList = [];
            this.page = 1;
            this.oldPage = 0;
            this.loading = false;
            this.finished = false;
            this.getHomeGoodsFunc();
        },
        // 跳转到搜索页面
        gotoPagesFunc(type, goodsid) {
            let url = '';
            let query = {};
            switch (type) {
                case 'search':
                    url = '/search';
                    break;
                case 'xs':
                    url = '/categary';
                    break;
                case 'goodsdetail':
                    url = '/goodsDetail';
                    query = {
                        goodsId: goodsid
                    }
                    break;
                case 'category':
                    url = '/categary';
                    query = {
                        category: goodsid
                    }
                    break;
            }
            this.$router.push({
                path: url,
                query: query
            })
        },
        // 查看公告
        openNoticeFunc(idx) {
            this.isShowGg = true;
            this.noticeDetail = {...this.noticeList[idx] }.concent
        },
        // banner跳转
        goLinkFunc(url) {
            if (url.indexOf('http://') != -1 || url.indexOf('https://') != -1) {
                window.location.href = url;
            } else {
                this.$router.push({
                    path: url
                })
            }
        },
        // 客服
        groupFun() {
            this.showGroup = true;
        },
        // 以了解
        knownFunc() {
            this.isFirst = -1;
            this.$cookies.set('isFirst', 1, '10y');
            this.isShowCoupon = true;
        },
        // 种草达人专享特权
        goDaPersonFunc() {
            this.$router.push({
                path: '/memberInterest'
            })
        },
        // 跳转到秒杀
        goSecondsKillFunc() {
            this.$router.push({
                path: '/secondsKill'
            })
        }
    }
}
